<template>
  <section>
    <div>
      <div class="w-full flex gap-4">
        <div class="w-3/12">
          <AutoComplete @item-select="eliminarArchivo" v-model="contratosStore._articulos.lista_precio_id" size="small" optionLabel="Nombre" dropdown :force-selection="true" inputStyle="" inputClass="w-full text-sm" panelClass="text-sm" class="w-full h-10" :suggestions="listaPrecioStore._listas_precio_buscadas" @complete="buscarListas($event)" />
          <span class="text-xs italic  text-gray-600">
            Si no encuentras el listado de precios adecuado
            <router-link target="_blank" :to="{name: 'pharmasan.ventas.lista-precio', target: '_blank', query: { crear: true }}"
              class="text-blue-600 font-bold italic"
            >
              +Crea uno
            </router-link>
          </span>
        </div>
        <div class="flex w-9/12 justify-end gap-2" v-if="contratosStore._articulos.lista_precio_id">
          <div class="w-3/12 h-11" v-if="documento">
            <div class="bg-blue-300 flex justify-between rounded-md h-full p-2 text-blue-600 font-bold text-sm border border-r-2">
              <div class="flex gap-2">
                <p>{{ documento ?  documento.name : 'articulos.xlsx' }}</p>
              </div>
              <button @click="eliminarArchivo">
                <i class="pi pi-times text-sm"></i>
              </button>
            </div>
          </div>
          <FileUpload
            v-else
            mode="basic"
            class="w-full h-10 bg-white text-blue-600"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            chooseLabel="Importar articulos masivo"
            @change="subirArticulos()"
            ref="file"
          ></FileUpload>
          <Button @click="contratosStore.descargarPlantillaMasivoArticulos()" class="h-10 w-4/12" label="Descargar plantilla articulos" icon="pi pi-download" severity="secondary" />
        </div>
      </div>
      <div v-if="contratosStore.articulos.articulos.length" class="p-2 rounded-md bg-gray-300 text-blue-600  mt-4 italic text-sm">
        <p>Se cargaron <span class="font-bold">{{ contratosStore.articulos.articulos.length }}</span> artículos</p>
      </div>
    </div>
  </section>
</template>
<script>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { useListaPrecioStore } from '../../../stores/lista-precio.store'
  import { ref, onMounted } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  // import { useToast } from 'primevue/usetoast'
  import { helper } from '@/utils/helper'
  import ServiceContrato from '../../../services/contrato-precio.service'
  // import XLSX from 'xlsx'
  // import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  export default {
    name: 'crearContratoPacientes',
    setup () {
      // const toast = useToast()
      const contratosStore = useContratosStore()
      const listaPrecioStore = useListaPrecioStore()
      const _serviceContrato = new ServiceContrato()
      const listaSeleccionada = ref()
      const file = ref()
      const documento = ref()
      const filtros = ref({
          ItemCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const subirArticulos = () => {
        const archivo = file.value.files[0]
        documento.value = archivo
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            contratosStore.medicamentos_ingresados = { base64: await helper.base64String(archivo) }
            const validArt = await _serviceContrato.validArticulos({ base64: await helper.base64String(archivo) }, contratosStore._articulos.lista_precio_id.ListPriceCL)
            if (validArt.data) {
              Swal.fire({
                icon: 'warning',
                title: 'Validacion Pacientes',
                text: validArt.data
              })
            }
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const buscarListas = ({ query }) => {
        listaPrecioStore.buscarListaPrecio(query || 'a')
      }
      const eliminarArchivo = () => {
        contratosStore.medicamentos_ingresados = null
        contratosStore.articulos.articulos = []
        documento.value = null
      }
      onMounted(() => {
        listaPrecioStore.listarListasPrecio()
      })
      return {
        contratosStore,
        listaPrecioStore,
        subirArticulos,
        file,
        buscarListas,
        listaSeleccionada,
        filtros,
        documento,
        eliminarArchivo
      }
    }
  }
</script>
